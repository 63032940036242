import React, { createContext, useContext, useState } from 'react';
import consultaWeb from '../services/consultaWeb';
import demandConsult from '../services/demandConsult';

interface IIdDemandContext {
  // cnjs: string[];
  // setCnjs: (data: string[]) => void;
  getIdDemand: (demandId: string) => any;
}
export const IdDemandContext = createContext<IIdDemandContext>({
  // cnjs: [],
  // setCnjs: (data) => {},
  getIdDemand: (demandId: string) => {},
});
const IdDemandProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // const [cnjs, setCnjs] = useState<string[]>([]);
  const getIdDemand = async (demandId: string): Promise<any> => {
    try {
      const options: any = [
        { apiKey: 'CodiloDtProjuris', key: 'codilo-dt-projuris' },
        { apiKey: 'CodiloDtSaj', key: 'codilo-dt-saj' },
        { apiKey: 'CodiloDtDev', key: 'codilo-dt-dev' },
        { apiKey: 'DatateamProd', key: 'datateam-prod' },
        { apiKey: 'DatateamSaj', key: 'datateam-saj' },
        { apiKey: 'DatateamProjuris', key: 'datateam-projuris' },
      ];

      let response = {
        key: 'not-found',
        data: [],
      };
      for (let i = 0; i < options.length; i++) {
        try {
          const option = options[i];
          const result = await demandConsult[`getDemand${option.apiKey}`]({ demandId });
          console.log(result);
          if (result) {
            response.key = option.key;
            response.data = result;
            break;
          }
        } catch (error) {
          console.log(error);
        }
      }
      return response;
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  };
  return (
    <IdDemandContext.Provider
      value={{
        getIdDemand,
      }}
    >
      {children}
    </IdDemandContext.Provider>
  );
};

const useIdDemand = (): IIdDemandContext => {
  const context = useContext(IdDemandContext);
  if (!context) {
    throw new Error('useIdDemand must be used within an IdDemandProvider');
  }
  return context;
};

export { IdDemandProvider, useIdDemand };
