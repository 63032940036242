import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Dashboard from '../../components/templates/Dashboard';
import { useIdDemand } from '../../hooks/useIdDemand';
import {
  Body,
  Button,
  ButtonContainer,
  Container,
  ContainerDescription,
  Content,
  Description,
  Form,
  Header,
  Textarea,
  Title,
  Input,
  InputContainer,
  InputLabel,
} from './styles';

const GetIdDemandContent: React.FC = () => {
  const { getIdDemand } = useIdDemand();
  const navigate = useNavigate();
  const idDemand = useRef<HTMLInputElement>(null);
  // const call = async ()=>{}
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const _idDemand = idDemand.current && idDemand.current.value;
    console.log(_idDemand);
    if (_idDemand) {
      try {
        const a = await getIdDemand(_idDemand);
        console.log(a);
      } catch (error) {
        console.log(error);
      }

      // navigate('/auto-request/monitoring');
    }
  };
  return (
    <Container>
      <Content>
        <Header>
          <Title>Busca por idDemanda</Title>
        </Header>
        <Body>
          <ContainerDescription>
            <Description>Digite o idDemanda que deseja buscar nos seguintes ambientes</Description>
            <span>Codilo DT - Projuris</span>
            <br />
            <span>Codilo DT - Saj</span>
            <br />
            <span>Codilo DT - Dev</span>
            <br />
            <span>Datateam antigo - Prod</span>
            <br />
            <span>Datateam antigo - Saj</span>
            <br />
            <span>Datateam antigo - Projuris</span>
            <br />
            <Description>
              A busca será realizar em cada um desses ambiente na ordem acima
            </Description>
          </ContainerDescription>
          <Form onSubmit={handleSubmit}>
            <InputContainer>
              {/* <InputLabel>ID DEMANDA</InputLabel> */}
              <Input
                ref={idDemand}
                type="text"
                name="idDemand"
                required
                placeholder="Digite o Id de busca"
              />
            </InputContainer>
            <ButtonContainer>
              <Button>Buscar</Button>
            </ButtonContainer>
          </Form>
        </Body>
      </Content>
    </Container>
  );
};
const GetIdDemand: React.FC = () => {
  return (
    <Dashboard>
      <GetIdDemandContent />
    </Dashboard>
  );
};

export default GetIdDemand;
