import { apiDemand } from './servers/apiDemand';

const demandConsult: any = {
  getDemandCodiloDtProjuris: async ({ demandId }: { demandId: string }) => {
    const res = await apiDemand.codiloDtProjuris.get(`/demand/${demandId}`, {});
    return res.data;
  },
  getDemandCodiloDtSaj: async ({ demandId }: { demandId: string }) => {
    const res = await apiDemand.codiloDtSaj.get(`/demand/${demandId}`);
    return res.data;
  },
  getDemandCodiloDtDev: async ({ demandId }: { demandId: string }) => {
    const res = await apiDemand.codiloDtDev.get(`/demand/${demandId}`);
    return res.data;
  },
  getDemandDatateamSaj: async ({ demandId }: { demandId: string }) => {
    const res = await apiDemand.datateamSaj.get(`/processo_by_demanda/${demandId}`);
    return res.data;
  },
  getDemandDatateamProjuris: async ({ demandId }: { demandId: string }) => {
    const res = await apiDemand.datateamProjuris.get(`/processo_by_demanda/${demandId}`);
    return res.data;
  },
  getDemandDatateamProd: async ({ demandId }: { demandId: string }) => {
    const res = await apiDemand.datateamProd.get(`/processo_by_demanda/${demandId}`);
    return res.data;
  },
};
export default demandConsult;
